<!--
 * @Descripttion:
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-03-13 10:05:54
-->
<template>
    <div>
        <div class="header">
          <img src="/static/img/intro/index/hiweke.png">
          <div class="header-middle">
            <div class="header-left">
              <h1>专家记</h1>
              <p>便携式移动专家库</p>
              <div class="info">为<span class="ye">招标代理机构</span>量身定制的<span class="ye">专家库抽取系统</span></div>
              <div class="ewm">
                <img src="/static/img/ewm.png" @click="ewmSrc = '/static/img/ewm.png'; showEwm = true">
                <div>
                  <p>手机扫码</p>
                  <p>即可使用专家记</p>
                </div>
              </div>
              <div style="height: 1rem"></div>
            </div>
            <img class="header-right" src="/static/img/intro/index/header-right.png" />
          </div>
        </div>
        <div class="ttbg"></div>
        <div class="flex-around up">
          <div class="menu">
            <span class="ww"></span>
            <h3>核心功能</h3>
            <i class="icon-zjk"></i>
            <h3 class="fc">专家库</h3>
            <p class="desc">组建招标代理机构专属评标专家库，专家信息清晰展示。</p>
          </div>
          <div class="menu">
            <span class="ww"></span>
            <h3>核心功能</h3>
            <i class="icon-zjk"></i>
            <h3 class="fc">专家抽取</h3>
            <p class="desc">结合招标代理机构的实际业务需求，通过智能化的抽取算法，为其量身定制了一套评标专家抽取系统。</p>
          </div>
        </div>
        <div class="titleDiv">
          <h3 class="title">
            <span class="c1"></span>
            <span class="c2"></span>
            <span class="c3"></span>
            <span class="c4"></span>
            <b>01</b>
          </h3>
          <h2>产品介绍</h2>
          <h2>PROJECT INTRODUCTION</h2>
        </div>
        <div class="flex-around left">
          <img src="/static/img/intro/index/left-1.png" class="left1">
          <p>专家记是为招标代理机构量身定制的一个专家库系统，让招标代理机构更加高效、专业地管理专家，更加便捷、智能地抽取专家。专家记将搭载微信公众号的手机端与功能完备的PC端加以整合，支持PC和移动多端使用，是一款能够装在口袋里的便携式移动专家库。</p>
        </div>
        <div class="flex-item left2">
          <img src="/static/img/intro/index/left-2.png">
          <div>
            <h3>招标代理机构</h3>
            <p>为代理机构量身定制的专家库抽取系统</p>
            <p>需要抽取专家进行评标的全国各行业招标代理机构都是我们的服务对象，专家记将为其提供安全、稳定、流畅的服务。</p>
          </div>
        </div>

        <div class="titleDiv">
          <h2>解决问题</h2>
          <h2>fix the problem</h2>
        </div>

        <div class="flex-normal t">
          <div class="q q1">
            <h1>01</h1>
            <p>整理不规范</p>
            <span>传统专家管理方式落后，专家信息冗长且分类不清，导致无法直接查看对应评审专业下的专家列表，无形中加大工作量。</span>
          </div>
          <div class="q q2">
            <h1>02</h1>
            <p>结果不适用</p>
            <span>以往评标专家的抽取方式简单，抽取结果往往不能满足实际工作的需要，经常需要多次抽取才能获得满足条件的专家，耗费了大量时间和精力。</span>
          </div>
          <div class="q q3">
            <h1>03</h1>
            <p>记录不清晰</p>
            <span>个别招标代理机构采用传统Excel表格的方式抽取评标专家，私下可操纵性强，无法做到抽取记录全追溯，有失采购活动的公正性原则。</span>
          </div>
          <div class="q q4">
            <h1>04</h1>
            <p>权限不明确</p>
            <span>操作员与管理员查看权限相同，导致个别操作员暗自联系专家并邀请“兄弟”公司前来投标，极大地损害了其他投标单位的利益。</span>
          </div>
        </div>

        <div class="titleDiv">
          <h3 class="title">
            <span class="c1"></span>
            <span class="c2"></span>
            <span class="c3"></span>
            <span class="c4"></span>
            <b>02</b>
          </h3>
          <h2>功能介绍</h2>
          <h2>function description</h2>
        </div>
        <div class="titleDiv">
          <h2>主要功能</h2>
          <h2>functions</h2>
        </div>

        <div>
          <div class="flex33">
            <div>
              <h3>专家抽取</h3>
              <p>从专家库中根据招标代理机构指定的抽取条件，使用智能抽取算法，随机抽取符合评标要求的专家。</p>
            </div>
            <div>
              <h3>项目记录</h3>
              <p>系统留存抽取痕迹，抽取结果公开透明，有据可依。</p>
            </div>
            <div>
              <h3>专家管理</h3>
              <p>创建评标专家信息，完善专家列表，方便查看，便于管理。</p>
            </div>
          </div>
          <div>
            <img src="/static/img/intro/index/bg2.png" class="w-100">
          </div>
          <div class="flex33 f2">
            <div>
              <h3>参与确认</h3>
              <p>与评标专家沟通后，确认其参与状态，不参与可另行补抽。</p>
            </div>
            <div>
              <h3>权限设定</h3>
              <p>管理员设定保密内容，操作员可根据管理员设定条件查看项目或进行其它操作。</p>
            </div>
            <div>
              <h3>定制专属专家库</h3>
              <p>从系统的评审专业库中挑选需要的评审专业，生成自己的评审专业库。</p>
            </div>
          </div>
        </div>

        <div class="titleDiv">
          <h3 class="title">
            <span class="c1"></span>
            <span class="c2"></span>
            <span class="c3"></span>
            <span class="c4"></span>
            <b>03</b>
          </h3>
          <h2>多端应用</h2>
          <h2>multiterminal application</h2>
        </div>
        <div class="ttt">
          <img src="/static/img/intro/index/img_bg.webp" class="w-100">
          <div class="tttt">
            <div class="titleDiv">
              <h2>手机端</h2>
              <h2>Phone side</h2>
            </div>
            <div class="mobile">
              <div class="flex-between">
                <h2>便携操作 — 手机端</h2>
                <img src="/static/img/intro/index/jp.png">
              </div>
              <p>手机端具备的功能有：专家管理、专家抽取、项目记录、评审专业等。专家记借助手机易携带、易移动等特点，将原有冗杂的功能全部赋能在手机端，使其成为一款为招标代理机构量身定制的，能够装在口袋里的专家库，不再受到时间与地点的限制，随时随地，即开即用，方便快捷。</p>
            </div>
          </div>
        </div>
        <div class="computer">
          <div class="titleDiv">
            <h2>电脑端</h2>
            <h2>computer side</h2>
          </div>
          <img src="/static/img/intro/index/computer.png" class="w-100">
          <div class="mobile cc">
            <p class="circle cc1"></p>
            <p class="circle cc2"></p>
            <p class="circle cc3"></p>
            <div>
              <div class="flex-between">
                <h2>稳定管理 — 电脑端</h2>
                <img src="/static/img/intro/index/jp.png">
              </div>
              <p>专家记PC端除具备手机端全部功能外，还增加了抽取结果一键打印、操作权限绑定、项目状态记录等几大功能模块，始终以其安全稳定的运行机制和精准高效的抽取效率受到用户的欢迎，是各行业招标代理机构不可或缺的专家抽取工具。</p>
            </div>
          </div>
        </div>
        <div class="titleDiv linkUs">
          <h3 class="title">
            <span class="c1"></span>
            <span class="c2"></span>
            <span class="c3"></span>
            <span class="c4"></span>
            <b>04</b>
          </h3>
          <h2>联系我们</h2>
          <h2 style="color:#009A86">contact us</h2>
        </div>
        <div class="link">
          <div class="link_emw">
            <div @click="ewmSrc = '/static/img/intro/index/ewm-1.webp'; showEwm = true"><img src="/static/img/intro/index/ewm-1.webp" class="w-100"></div>
            <div @click="ewmSrc = '/static/img/intro/index/ewm-2.webp'; showEwm = true"><img src="/static/img/intro/index/ewm-2.webp" class="w-100"></div>
          </div>
          <p>请添加我们的企业微信</p>
          <p>随时帮您解答疑问~</p>
        </div>


        <div class="rightIcon">
             <div class="center" @click="showQuestion = !showQuestion">
                <i class="icon-question"></i>
                <p class="small" >常见问题</p>

             </div>
             <div class="center" @click="goTop">
                <i class="icon-top"></i>
                <p class="small">返回顶部</p>
             </div>
             <div class="questionDiv" :class="{tt : !showQuestion}">
                 <div>
                    <h3 class="fc pad02">常用操作</h3>
                    <div class="pad02" @click="$router.push('/chouqu')">
                        <p>用户如何进行<span class="fc">专家抽取</span>？</p>
                         <van-icon name="arrow" size="0.4rem" />
                    </div>
                    <div class="pad02" @click="$router.push('/zhuanjia')">
                        <p>用户如何<span class="fc">添加评标专家</span>？</p>
                         <van-icon name="arrow" size="0.4rem" />
                    </div>
                    <div class="pad02" @click="$router.push('/zhuanyeku')">
                        <p>用户如何<span class="fc">创建评标专业库</span>？</p>
                         <van-icon name="arrow" size="0.4rem" />
                    </div>
                    <div class="pad02" @click="$router.push('/buchou')">
                        <p>用户如何进行<span class="fc">专家补抽</span>操作？</p>
                         <van-icon name="arrow" size="0.4rem" />
                    </div>
                    <div class="pad02" @click="$router.push('/mobile')">
                        <p>用户如何进行<span class="fc">手机端专家记</span>？</p>
                         <van-icon name="arrow" size="0.4rem" />
                    </div>
                    <div class="pad02" @click="$router.push('/pc')">
                        <p>用户如何进行<span class="fc">电脑端专家记</span>？</p>
                         <van-icon name="arrow" size="0.4rem" />
                    </div>
                 </div>
             </div>
         </div>

         <!-- <div class="center">
           <a href="https://beian.miit.gov.cn" class="a-href">京ICP备2021026150号</a>
         </div> -->

         <van-popup v-model="showEwm" class="popImg">
           <img :src="ewmSrc" class="w-100">
         </van-popup>

    </div>
</template>

<script>
import {Icon, Popup} from 'vant'
export default {
    data(){
        return{
            showQuestion: false,
            showEwm : false,
            ewmSrc: ''
        }
    },

    components:{
        [Icon.name]:Icon,
        [Popup.name]:Popup
    },

    mounted(){
      this.countPageViewNum()
    },

    methods: {
        goTop(){
            document.body.scrollIntoView()
        },

        countPageViewNum(){
          this.$ajax({
            method: 'put',
            url: '/gateway/ed/thanks/tbEdThanks/updatePageViewNum',
          }).then( ({data}) => {
            console.log(data);
          })
        }

    }
}
</script>

<style scoped>
/* 常见问题---开始 */
/** 图标 */
.icon-zjk{background-image: url(/static/img/intro/icon-zjk.png); display: block; width: 1.8rem; height: 1.8rem; background-position: center;}
.icon-zjcq{background-image: url(/static/img/intro/icon-zjcq.png); display: block;width: 1.8rem; height: 1.8rem;}
.icon-question{background-image: url(/static/img/intro/icon_question.png); width: 0.6rem; height: 0.6rem; margin: 0 auto;}
.icon-top{background-image: url(/static/img/intro/icon_top.png); width: 0.6rem; height: 0.6rem; margin: 0 auto;}

/** 顶部右侧 */
.rightIcon{ width: 0.8rem; padding:0.2rem 0; background-color: #fff; position: fixed; right: 0; top: 7rem;}
.small{font-size: 0.3rem;line-height: 0.5rem; width: 170%; transform: scale(0.5) translateX(-0.55rem); position: relative;}

.tt{color: #737373; font-size: 0.3rem; line-height: 0.54rem; transform: scale(0.8);}
.tt{opacity: 0; display: none;}
.questionDiv{ position: absolute; top: 0; right: 0.85rem; width: 5.5rem; background-color: #fff; transition: all .5s ease-in; border-radius: 0.3rem; z-index: 20; border-radius: .2rem; }
.questionDiv h3{background-color: #E1FFFB; border-top-left-radius: .2rem;}
.questionDiv>div>div{display: flex; justify-content: space-between; align-items: center;}
.questionDiv p, .questionDiv span{font-size: 0.35rem;}

/* 常见问题---结束 */
/*** 页面顶部 -- 开始*/
.header{
  background: url(/static/img/intro/index/header-bg.png) center / 100% 100% no-repeat;
  background-size: cover;
  padding-top: .75rem;
  padding-left: .59rem;
  position: relative;
}
.header> img {width: 1rem; height: auto;}
.header {color: white;}
.header-middle {display: flex; align-items: center; overflow: hidden;}
.header-left{position: relative; white-space: nowrap; width: 50%; padding-left: .32rem;}
.header-left >h1 {font-size: .75rem; line-height: .75rem; margin-top: .21rem;}
.header-left >p {font-size: .45rem; line-height: .45rem; margin-top: .21rem; letter-spacing: .2rem;}
.header-left .info {margin-top: .5rem; padding: .11rem .2rem; border-radius: .5rem; background: linear-gradient(90deg, rgba(128, 230, 217, 0.5) 0%, rgba(255, 255, 255, 0) 100%);}
.header-left .info, .header-left .info span {font-size: .25rem; line-height: .25rem;}
.header-left .ewm {display: flex; align-items: center; margin-top: 1.6rem;margin-bottom: 1.5rem;}
.header-left .ewm > img {width: 1.71rem; height: 1.71rem;}
.header-left .ewm p {font-size: .29rem; padding-left: .21rem;}
.header-right{width: 55%; height: auto; flex-shrink: 0; align-self: flex-start;}
.menu {display: flex; flex-direction: column; align-items: center; height: 6.5rem; padding: .32rem .21rem; border-radius: .2rem; width: 35%; text-align: center; background: linear-gradient(180deg, #FFFFFF 0%, #E1FFFB 100%); }
.ye{color: #FFCC78;}
.up {margin-top: -3rem; position: relative;}
.desc {color: #595959; font-size: .32rem; line-height: .52rem; text-align: left;}
.ttbg{background-color: #00CCB2; height: 2rem; margin-top: -.5rem;}
.ww {background-color: #00CCB2; width: 1rem; height: 0.15rem; border-radius: .1rem; display: inline-block; margin-top: .21rem; margin-bottom: .21rem;}
/** 页面顶部 -- 结束 */

.title {position: relative; margin: 0 auto; width: 3.5rem; text-align: center; height: 1.2rem; line-height: 1.2rem; padding: 0 0.5rem;}
.title::after{border: none;}
.title >span {width: .3rem; height: .3rem; border-radius: 50%; position: absolute;}
.title .c1 { top: 0.2rem; left: 0.2rem; }
.title .c2 { top: 0.2rem; right: 0.2rem; }
.title .c3 { bottom: 0.3rem; left: 0.2rem; }
.title .c4 { bottom: 0.3rem; right: 0.2rem; }
.title .c1, .title .c4{background-color: #84e4d8;}
.title .c2, .title .c3{background-color: #EDDCB6;}
.title > b { font-size: .56rem; background-color: #00CCB2; padding: .21rem; position: relative;}
.title> b::before {position: absolute;left: 1.04rem; border-style: solid; content: ''; height: 0; width: 0; border-color: transparent  transparent transparent #00CCB2; border-width: 0.52rem 0.72rem ;}
.title> b::after {position: absolute;right: 1.04rem; top: 0; border-style: solid; content: ''; height: 0; width: 0; border-color: transparent #00CCB2  transparent transparent ; border-width: 0.52rem 0.72rem ;}
.titleDiv {text-align: center; margin: .75rem 0;}
.titleDiv > h2 {font-size: .6rem; text-align: center; line-height: .7rem;}
.titleDiv > h2:last-of-type {color: #737373; font-size: .37rem; text-transform: uppercase; }
.left > img {width: 43.61%;}
.left > p {width: 35%; color: #737373; line-height: .64rem;}
.left2 {position: relative; margin-top: .52rem;}
.left2 > img {width: 66.67%;}
.left2 > div {width: 45%; margin-left: -22.5%;background-color: #00AA94; padding: .32rem; color: white; border-radius: .2rem;}
.left2 > div p { font-size: .3rem; line-height: .5rem; margin: 0.21rem 0; color: #E1FFFB;}

.t {flex-wrap: wrap;
background: linear-gradient(0deg, #E1FFFB 0%, rgba(128, 230, 217, 0) 100%);}
.q{ padding: .21rem .43rem .52rem; text-align: center; width: 40%; flex-shrink: 0; margin-top: .43rem;}
.q:nth-child(odd){margin-left: 7.5%; margin-right: 2.5%;}
.q:nth-child(even){margin-left: 2.5%; margin-right: 7.5%;}
.q > h1 {color: #80E6D9; font-size: .85rem;}
.q > p {background-color: #00CCB2; color: white; margin-top: .32rem; font-size: .45rem; padding: .11rem .21rem; border-radius: .5rem;}
.q > span {line-height: .64rem; color: white; text-align: left; display: inline-block; margin-top: .43rem;}
.q1{background: url(/static/img/intro/index/q1.png) center /  100% 100% no-repeat}
.q2{background: url(/static/img/intro/index/q2.png) center /  100% 100% no-repeat}
.q3{background: url(/static/img/intro/index/q3.png) center /  100% 100% no-repeat}
.q4{background: url(/static/img/intro/index/q4.png) center /  100% 100% no-repeat}

.flex33{display: flex; justify-content: space-around;}
.flex33>div{ width: 30%; flex-shrink: 0;padding: .32rem; position: relative;
background: linear-gradient(180deg, #E1FFFB 0%, #FFFFFF 100%);}
.flex33>div:nth-of-type(1){top: 2rem;}
.flex33>div:nth-of-type(2){top: 1rem;}
.flex33>div>h3::before{content: ''; margin-top: -0.12rem; margin-right: 0.1rem; border: 0.06rem solid #009A86; display: inline-block; width:0.2rem; height: 0.2rem; border-radius: 50%;}
.flex33>div:nth-of-type(2)>h3::before{border-color: #FFC000;}
.flex33>div:nth-of-type(3)>h3::before{border-color: #80E6D9;}
.f2>div:nth-of-type(1)>h3::before{border-color: #00A8FF;}
.f2>div:nth-of-type(2)>h3::before{border-color: #FF7A7A;}
.f2>div:nth-of-type(3)>h3::before{border-color: #B83BFF;}
.f2>div:nth-of-type(1){top: -2rem;}
.f2>div:nth-of-type(2){top: -1rem;}
.f2>div{
background: linear-gradient(135deg, #E1FFFB 0%, #FFFFFF 100%);}
.flex33>div>p{font-size: .32rem; line-height: .64rem;}

.ttt {position: relative;}
.ttt .titleDiv>h2:nth-of-type(2) {color: white;}
.tttt {position: absolute;top: 0; text-align: center; width: 100%; padding: .3rem;}
.tttt .titleDiv{margin: .32rem 0;}
.mobile {background-color: #009A86; padding: .43rem; border-radius: .2rem;}
.mobile h2 {color: white; font-size: .56rem;}
.mobile img {height: .56rem;}
.mobile p{color: white; text-align: left; font-size: .32rem; line-height: .64rem; margin-top: .21rem;}
.computer .titleDiv{margin-bottom: -0.5rem;}
.computer .mobile{background-color:unset; padding: .43rem;}
.computer .mobile>div {background-color: #80E6D9; padding: .32rem; border-radius: .2rem; box-shadow: .2rem .2rem .5rem  #C1F3ED; position: relative; z-index: 2;}

.cc {padding-bottom: 1rem; position: relative;}
.circle{border-radius: 50%; position: absolute;}
.cc1 {background-color: #F7EBD6; width: 3rem; height: 3rem; top: 2.5rem; left: -1rem;}
.cc2 {background-color: #F7EBD6; width: 1rem; height: 1rem; top: -0.5rem; right: .5rem;}
.cc3 {background-color: #EBFCFA; width: 1.5rem; height: 1.5rem; bottom: -0.5rem; right: 3rem;}

.cc h2, .cc p{color: #1E1E1E;}

.icon-wx{background: url(/static/img/intro/index/wx.png) center / 100% 100% no-repeat; width: 1.01rem; height: 1.01rem;}
.icon-qq{background: url(/static/img/intro/index/qq.png) center / 100% 100% no-repeat; width: 1.01rem; height: 1.01rem;}
.icon-email{background: url(/static/img/intro/index/email.png) center / 100% 100% no-repeat; width: 1.01rem; height: 1.01rem;}
.link {margin: 1rem .53rem; background-color: #E1FFFB; border-radius: .2rem;}
.link_emw{display: flex; justify-content: space-around; width: 100%; }
.link_emw>div{flex-shrink: 0; width: 30%; text-align: center; padding: 0.53rem 0;}
.link_emw img{ box-shadow: #80E6D9 0.2rem  0.2rem 0;}
.link p {text-align: center;}
.link p:first-of-type{color: #1E1E1E; font-weight: bold;}
.link p:last-of-type{color: #00C0A7; padding-bottom: 0.5rem; font-size: .32rem;}
.linkUs>h2 {color: #009A86;}
.popImg{width: 80%; max-width: 200px;}
.a-href {font-size: 0.35rem; color: #6a7481; display: inline-block; padding-bottom: 1rem;}
</style>
